import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { fetchData, fetchObject, UserData } from '../../utils/shop'
import http from '../../utils/axios'
import { useAuthStore } from '../../store/authContext'
import useAxios from '../../utils/useAxios'

function ProductDetail({isLoggedIn}) {

    const isAuth = isLoggedIn

    const slug = useParams('slug').slug
    const [loading, setLoading] = useState(true)

    const [productDetail, setProductDetail] = useState([])
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])

    const [inlineData, setInlineData] = useState('productDescription')

    const [transformMainImage, setTransformMainImage] = useState('')

    const [buttonIndex, setButtonIndex] = useState(0)

    const userData = UserData()

    useEffect(() => {
        fetchData(`/shop/product-detail/${slug}`, setProductDetail, setLoading)
        fetchObject(`/shop/categories`, setCategories, setLoading)
        fetchObject(`/shop/sub-categories`, setSubCategories, setLoading)
        const defaultWeight = productDetail?.map((p, a) => (p?.weight[0]))

    }, [setProductDetail, setCategories, setSubCategories])
    

    // Quantity data
    const [quantity, setQuantity] = useState(1)

    const handleQuantityPlus = () => {
        setQuantity((prevIndex) => prevIndex + 1)
    }

    const handleQuantityMinus = () => {
        setQuantity((prevIndex) => prevIndex <= 1 ? prevIndex : prevIndex - 1)
    }

    const handleQuantityChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 1) {
          setQuantity(value);
        } else if (e.target.value === '') {
          setQuantity('');
        }
    }

    const handleQuantityBlur = () => {
        if (quantity === '') {
          setQuantity(1);
        }
    }

    // Cart data

    const request = useAxios()

    const handleAddToCart = async () => {
        const product = productDetail[0];
        const payload = {
            user: userData.user_id,
            cart: userData.cart_id,
            product: product,
            sku: product.sku,
            weight: weight,
            quantity: quantity,
            price: product?.discount_price ? product.discount_price : product.price,
        }
        console.log(payload)
        await request.post('/order/add-cart', payload)
        .then(response => {
            window.location.reload()
            // console.log('Cart Created:', response.data);
        })
        .catch(error => {
            // console.log(payload)
            console.error('Error adding to cart:', error);
        });
    };


    // Weight data
    const [weight, setWeight] = useState(productDetail[0]?.weight[0]?.weight)

    useEffect(() => {
        if (productDetail[0]?.weight?.length) {
            setWeight(productDetail[0].weight[0].weight);
        }
    }, [productDetail]);

    const handleWeight = (e) => {
        const selectedWeight = e.target.value;
        setWeight(selectedWeight);
        console.log(weight)
    };
    console.log(productDetail[0]?.weight[0]?.weight)

    // Images data

    const handleImageRightButton = () => {
        setButtonIndex((prevIndex) => imagesNumber > prevIndex ? prevIndex + 1 : prevIndex)
    }

    const handleImageLeftButton = (e) => {
        setButtonIndex((prevIndex) => prevIndex > 0 ? prevIndex - 1 : prevIndex)
    }

    // Other info data

    const handleDescription = () => {
        setInlineData('productDescription')
    }

    const handleAdditionalInfo = () => {
        setInlineData('productInfo')
    }

    // console.log(categories)
  return (
    <div role="main" className="main shop py-4">
        <div className="container">
            {productDetail?.map((p, index) => (
            <div key={index} className="row">
                <div className="col-lg-3">
                    <aside className="sidebar">
                        <form action="page-search-results.html" method="get">
                            <div className="input-group mb-3 pb-1">
                                <input className="form-control text-1" placeholder="Search..." name="s" id="s" type="text"/>
                                <button type="submit" className="btn btn-dark text-1 p-2"><i className="fas fa-search m-2"></i></button>
                            </div>
                        </form>


                        
                        <h5 className="font-weight-semi-bold pt-3">Categories</h5>
                        <ul className="nav nav-list flex-column">
                        {categories.map((c, index) => (
                            <li key={index} className="nav-item"><a className="nav-link" href={`/${c.slug}/products`}>{c.name}</a></li>
                        ))}
                        </ul>
                        <h5 className="font-weight-semi-bold pt-5">Sous-Categories</h5>
                        <div className="mb-3 pb-1">
                        {categories.map((c, indexC) => (
                        <div key={indexC}>
                            {c.sub_category.map((s, indexS) => (
                            <a key={indexS} href={`/${s.slug}/products`}>
                                <span className="badge badge-dark badge-sm rounded-pill text-uppercase px-2 py-1 me-1">
                                {s.name}
                                </span>
                            </a>
                            ))}
                        </div>
                        ))}
                        </div>



                        <div className="row mb-5">
                            <div className="col">
                                <h5 className="font-weight-semi-bold pt-5">Related Products</h5>
                                <div className="product row row-gutter-sm align-items-center mb-4">
                                    <div className="col-5 col-lg-5">
                                        <div className="product-thumb-info border-0">
                                            <a href="shop-product-sidebar-left.html">
                                                <div className="product-thumb-info-image">
                                                    <img alt="" className="img-fluid" src="img/products/product-grey-6.jpg"/>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-7 col-lg-7 ms-md-0 ms-lg-0 ps-lg-1 pt-1">
                                        <a href="#" className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-2">hat</a>
                                        <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0"><a href="shop-product-sidebar-right.html" className="text-color-dark text-color-hover-primary text-decoration-none">Blue Hat</a></h3>
                                        <div title="Rated 5 out of 5">
                                            <input type="text" className="d-none" value="5" title="" data-plugin-star-rating data-plugin-options="{'displayOnly': true, 'color': 'dark', 'size':'xs'}"/>
                                        </div>
                                        <p className="price text-4 mb-0">
                                            <span className="sale text-color-dark font-weight-semi-bold">$299,00</span>
                                            <span className="amount">$289,00</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                <div className="col-lg-9">

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="thumb-gallery-wrapper">
                                <div key={index}>
                                    <div className="thumb-gallery-detail owl-theme manual nav-inside nav-style-1 nav-dark mb-3 owl-loaded owl-drag">
                                        <div className="owl-stage-outer owl-height" style={{height: "403.5px"}}>
                                            <div className="owl-stage" style={{transform: "translate3d(0px, 0px, 0px)", transition: "all", width: "2068px"}}>
                                                {p.images.map((i, index) => (
                                                    <div key={index} className="owl-item active" style={{width: "403.5px", marginRight: "10px"}}>
                                                        <img alt="" className="img-fluid" src={i.image} data-zoom-image="img/products/product-grey-7.jpg"/>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {/* <div className="owl-nav">
                                            <button onClick={handleImageLeftButton} type="button" role="presentation" className={`owl-prev disabled`}></button>
                                            <button onClick={handleImageRightButton} type="button" role="presentation" className="owl-next disabled"></button>
                                        </div> */}
                                        <div className="owl-dots disabled"></div>
                                    </div>
                                    <div className="thumb-gallery-thumbs owl-carousel owl-theme manual thumb-gallery-thumbs">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="summary entry-summary position-relative">

                                <div className="position-absolute top-0 right-0">
                                    <div className="products-navigation d-flex">
                                        <a href="#" className="prev text-decoration-none text-color-dark text-color-hover-primary border-color-hover-primary" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-original-title="Red Ladies Handbag"><i className="fas fa-chevron-left"></i></a>
                                        <a href="#" className="next text-decoration-none text-color-dark text-color-hover-primary border-color-hover-primary" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-original-title="Green Ladies Handbag"><i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                                    <div key={index}>
                                        <h1 className="mb-0 font-weight-bold text-7">{p.name}</h1>
                                        <div className="pb-0 clearfix d-flex align-items-center">
                                            <div style={{cursor: 'pointer'}} className="review-num">
                                                {p.category ? 
                                                <>
                                                <Link to={`/category/${p.category.slug}/products`} className="text-decoration-none text-color-default text-color-hover-primary" data-hash data-hash-offset="0" data-hash-offset-lg="75" data-hash-trigger-click=".nav-link-reviews" data-hash-trigger-click-delay="1000">
                                                    {p.category.name}
                                                </Link>
                                                {' > '}
                                                <Link className="text-decoration-none text-color-default text-color-hover-primary" data-hash data-hash-offset="0" data-hash-offset-lg="75" data-hash-trigger-click=".nav-link-reviews" data-hash-trigger-click-delay="1000">
                                                    {p.sub_category.name}
                                                </Link>
                                                </>
                                                : ''}
                                                
                                            </div>
                                        </div>
                                        <div className="divider divider-small">
                                            <hr className="bg-color-grey-scale-4"/>
                                        </div>

                                        <p className="price mb-3">
                                            <span className="sale text-color-dark">{p.discount_price ? p.discount_price : p.price} €</span>
                                            <span className="amount">{p.discount_price ? p.price : ''}</span>
                                        </p>

                                        <p className="text-3-5 mb-3">{p.title}</p>

                                        <ul className="list list-unstyled text-2">
                                            <li className="mb-0">DISPONIBILITE : <strong className="text-color-dark">{p.quantity == 0 ? 'Rupture de stock' : p.quantity + ' unités'}</strong></li>
                                            <li className="mb-0">SKU : <strong className="text-color-dark">{p.sku}</strong></li>
                                        </ul>

                                        <div encType="multipart/form-data" className="cart">
                                        {p.weight.length != 0 &&
                                            <table className="table table-borderless" style={{maxWidth: '300px'}}>
                                                <tbody>
                                                    <tr>
                                                        <td className="align-middle text-2 px-0 py-2">POIDS :</td>
                                                        <td className="px-0 py-2">
                                                            <div className="custom-select-1">

                                                                <select onClick={(e) => handleWeight(e)} className="form-control form-select text-1 h-auto py-2" required>
                                                                    {/* <option value="Choisissez votre poids" disabled>Choisissez votre poids</option> */}
                                                                    {p.weight.map((w, index) => (
                                                                        
                                                                        <option key={index} value={w.weight}>{w.weight + w.unit}</option>
                                                                        
                                                                    ))}
                                                                </select>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            }
                                            <hr/>
                                            <div className="quantity quantity-lg">
                                                <input onClick={handleQuantityMinus} type="button" className="minus text-color-hover-light bg-color-hover-primary border-color-hover-primary" value="-"/>
                                                <input type="text" className="input-text qty text" title="Qty" value={quantity} onChange={handleQuantityChange} onBlur={handleQuantityBlur}/>
                                                <input onClick={handleQuantityPlus} type="button" className="plus text-color-hover-light bg-color-hover-primary border-color-hover-primary" value="+"/>
                                            </div>
                                            {isAuth ?
                                                <button onClick={handleAddToCart} type="submit" className="btn btn-dark btn-modern text-uppercase bg-color-hover-primary border-color-hover-primary">Add to cart</button>
                                                :
                                                <Link to={'/login'} type="submit" className="btn btn-dark btn-modern text-uppercase bg-color-hover-primary border-color-hover-primary">Se connecter pour ajouter au panier</Link>
                                            }
                                            <hr/>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <ul className="social-icons social-icons-medium social-icons-clean-with-border social-icons-clean-with-border-border-grey social-icons-clean-with-border-icon-dark me-3 mb-0">
                                                <li className="social-icons-facebook">
                                                    <a href="http://www.facebook.com/sharer.php?u=https://www.okler.net" target="_blank" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-placement="top" title="Share On Facebook">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li className="social-icons-googleplus">
                                                    <a href="https://plus.google.com/share?url=https://www.okler.net" target="_blank" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-placement="top" title="Share On Google+">
                                                        <i className="fab fa-google-plus-g"></i>
                                                    </a>
                                                </li>
                                                <li className="social-icons-twitter">
                                                    <a href="https://twitter.com/share?url=https://www.okler.net&amp;text=Simple%20Share%20Buttons&amp;hashtags=simplesharebuttons" target="_blank" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-placement="top" title="Share On Twitter">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li className="social-icons-email">
                                                    <a href="mailto:?Subject=Share This Page&amp;Body=I%20saw%20this%20and%20thought%20of%20you!%20 https://www.okler.net" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-placement="top" title="Share By Email">
                                                        <i className="far fa-envelope"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                            <a href="#" className="d-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-semibold text-2">
                                                <i className="far fa-heart me-1"></i> SAVE TO WISHLIST
                                            </a>
                                        </div>
                                    </div>



                            </div>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col">
                            <div id="description" className="tabs tabs-simple tabs-simple-full-width-line tabs-product tabs-dark mb-2">
                                <ul className="nav nav-tabs justify-content-start">
                                    <li onClick={() => handleDescription()} className="nav-item"><a style={{cursor: 'pointer'}} className={`nav-link font-weight-bold text-3 text-uppercase py-2 px-3 ${inlineData == 'productDescription' ? 'active' : ''}`} data-bs-toggle="tab">Description</a></li>
                                    <li onClick={() => handleAdditionalInfo()} className="nav-item"><a style={{cursor: 'pointer'}} className={`nav-link font-weight-bold text-3 text-uppercase py-2 px-3 ${inlineData == 'productInfo' ? 'active' : ''}`} data-bs-toggle="tab">Informations Supplémentaire</a></li>
                                </ul>
                                <div className="tab-content p-0">
                                    {inlineData == 'productDescription' &&
                                    <div>
                                        <div className="tab-pane px-0 py-3" id="productDescription">
                                            <p>{p.description}</p>

                                        </div>
                                    </div>
                                    }
                                    {inlineData == 'productInfo' &&
                                    <div>
                                        <div className="tab-pane px-0 py-3" id="productInfo">
                                            <table className="table table-striped m-0">
                                                <tbody>
                                                    {p.specification.map((s, index) => (
                                                    <tr key={index}>
                                                        <th className="border-top-0">
                                                            {s.key}:
                                                        </th>
                                                        <td className="border-top-0">
                                                            {s.info}
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    }
                                    <hr className="solid my-0"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ))}
        </div>
    </div>
  )
}

export default ProductDetail
