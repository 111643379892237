import {useAuthStore} from '../store/authContext'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'js-cookie'
import Swal from 'sweetalert2'
import http from './axios'

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
})


export const login = async (email, password) => {
    try{
        const {data, status} = await http.post('auth/token', {
            email,
            password
        })

        if (status == 200) {
            setAuthUser(data.access, data.refresh)
            Toast.fire({
                icon: 'success',
                title: 'Login Succesfully'
            })
        }
        return {data, error: null}

    } catch (error) {
        return {
            data: null,
            error: error.response?.data?.detail || 'An error occurred'
        }
    }
}

export const register = async (email, password, password2) => {
    try {
        const {data} = await http.post('auth/register', {
            email,
            password,
            password2
        })

        // await login(email, password)

        return {
            data,
            error:null
        }
    } catch (error) {
        console.log(error)

        return {
            data: null,
            error: error.response?.data?.detail
        }
    }
}

export const logout = () => {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')
    useAuthStore.getState().setUser(null)
}

export const setUser = async () => {
    const accessToken = Cookies.get('access_token') || ""
    const refreshToken = Cookies.get('refresh_token') || ""

    if (!accessToken || !refreshToken) {
        return;
    }

    if (isAccessTokenExpired(accessToken)) {
        const response = await getRefreshToken(refreshToken)
        setAuthUser(response.access, response.refresh)
    } else {
        setAuthUser(accessToken, refreshToken)
    }
}

export const setAuthUser = (accessToken, refreshToken) => {
    Cookies.set('access_token', accessToken, {
        expires: 1,
        secure: true
    })

    Cookies.set('refresh_token', refreshToken, {
        expires: 1,
        secure: true
    })

    const user = jwtDecode(accessToken) ?? null

    if (user) {
        useAuthStore.getState().setUser(user)
    }

    useAuthStore.getState().setLoading(false)
}

export const getRefreshToken = async () => {
    const refresh_token = Cookies.get('refresh_token')
    const response = await http.post('auth/refresh', {
        refresh: refresh_token
    })
    return response.data
}

export const isAccessTokenExpired = (accessToken) => {
    try {
        const decodedToken = jwtDecode(accessToken)
        return decodedToken.exp < Date.now() / 100
    } catch (error) {
        // console.error(error)
        return true
    }
}
