import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import http from './axios';
import useAxios from './useAxios';

const request = useAxios()

export const fetchObject = async (endpoint, setDataFunction, setLoadingFunction) => {
    try {
        const response = await http.get(endpoint);

        setDataFunction(response.data);
        if (response.data) {
            setLoadingFunction(false)
        }
    } catch (error) {
        console.log(error);
    }
}

export const fetchData = async (endpoint, setDataFunction, setLoadingFunction) => {
    try {
        const response = await http.get(endpoint);

        setDataFunction([response.data]);
        if (response.data) {
            setLoadingFunction(false)
        }
    } catch (error) {
        console.log(error);
    }
}

export const fetchDataForUser = async (endpoint, setDataFunction, setLoadingFunction) => {
    try {
        const response = await request.get(endpoint);

        setDataFunction([response.data]);
        if (response.data) {
            setLoadingFunction(false)
        }
    } catch (error) {
        // console.log(error);
    }
}

export const UserData = () => {
    let access_token = Cookies.get('access_token');
    let refresh_token = Cookies.get('refresh_token');

    if (access_token && refresh_token) {

        const token = refresh_token;
        const data = jwtDecode(token);

        return data;
    } else {
        const data = {'response': 'error'}
        return data
    }
}