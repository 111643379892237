import React from 'react'
import { Link } from 'react-router-dom'

import Banner from './menu/banner'
import StripeValues from './menu/stripeValues'
import Categories from './menu/categories'
import Values from './menu/values'

function Menu() {
  return (
    <>
      <Banner />
      <Values />
      <StripeValues />
      <Categories />
    </>
  )
}

export default Menu
