import axios from 'axios'
import { getRefreshToken, isAccessTokenExpired, setAuthUser } from './auth'
import { API_BASE_URL } from './constants'
import Cookies from 'js-cookie'

const useAxios = () => {
    let customHeaders = {}
    const accessToken = Cookies.get('access_token') || ""
    const refreshToken = Cookies.get('refresh_token') || ""

    if (accessToken && refreshToken) {
        customHeaders = { Authorization: `Bearer ${accessToken}` }
    } else {
        customHeaders = { Accept: 'application/json' }
    }

    const axiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: customHeaders,
    });

    axiosInstance.interceptors.request.use(async (req) => {
        if (!isAccessTokenExpired(accessToken) && accessToken) {
            return req;
        } else if (refreshToken) {
            // Token d'accès expiré et `refresh_token` présent, on rafraîchit le token
            try {
                const response = await getRefreshToken();
                setAuthUser(response.access, response.refresh);
                
                // Ajoutez le nouveau token d'accès dans l'en-tête Authorization
                req.headers.Authorization = `Bearer ${response.access}`;
                return req;
            } catch (error) {
                console.error("Erreur lors du rafraîchissement du token:", error);
                throw error;
            }
        }       
    });
    return axiosInstance
};

export default useAxios
