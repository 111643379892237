import React from 'react'

function StripeValues() {
  return (
    <section className="section section-primary border-top-0 mb-0">
        <div className="container">
            <div className="row counters counters-sm counters-text-light">
                <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                    <div className="counter">
                        <strong data-to="40000" data-append="+">0</strong>
                        <label className="opacity-5 text-4 mt-1">Happy Clients</label>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                    <div className="counter">
                        <strong data-to="3500" data-append="+">0</strong>
                        <label className="opacity-5 text-4 mt-1">Answered Tickets</label>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">
                    <div className="counter">
                        <strong data-to="16">0</strong>
                        <label className="opacity-5 text-4 mt-1">Pre-made Demos</label>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="counter">
                        <strong data-to="3000" data-append="+">0</strong>
                        <label className="opacity-5 text-4 mt-1">Development Hours</label>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default StripeValues
