import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { useAuthStore } from '../../store/authContext'
import { fetchDataForUser } from '../../utils/shop'
import { UserData } from '../../utils/shop'
import TopOrderStep from './TopOrderStep'
import useAxios from '../../utils/useAxios'

function Cart() {
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn())
	const userData = UserData()
	const request = useAxios()
	const userUid = userData.uid
	
	const [cartData, setCartData] = useState([])
	const [loadingCartData, setLoadingCartData] = useState(false)
	const [loadingCartQuantity, setLoadingCartQuantity] = useState(true)

	useEffect(() => {
        fetchDataForUser(`order/cart/${userUid}`, setCartData, setLoadingCartData)
		setLoadingCartData(true)
	}, [userUid])

	const cartProducts = cartData[0]?.product
	const [quantities, setQuantities] = useState([]);
	useEffect(() => {
		if (cartProducts?.length) {
			setQuantities(cartProducts?.map(p => p.quantity || 1));
			setLoadingCartQuantity(true);
		}
	}, [cartProducts]);

	const updatedCartProducts = cartProducts?.map((p, index) => ({...p,  quantity: quantities[index]}))

	// Send data to change quantity
	const handleChangeQuantity = async () => {
		const payload = updatedCartProducts
		await request.patch('/order/change-cart', payload)
        .then(response => {
            window.location.reload()
        })
        .catch(error => {
            // console.log(payload)
            console.error('Error changing cart:', error);
        });
	}

	// Delete product from cart
	const handleDeleteProduct = async (cpid, cid) => {
		const payload = { cpid, cid };
		try {
			const response = await request.delete('/order/delete-cart', { data: payload })
			.then(response => {
			window.location.reload()
			// console.log('Product deleted successfully', response);
			})
			.catch(error => {
				// console.error('Error deleting product', error);
			});
		} catch (error) {
			console.error('Error sending the request', error);
		}
	};

	// Quantity handle
	const handleQuantityPlus = (index) => {
		setQuantities(prevQuantities =>
		  prevQuantities?.map((q, i) => (i === index ? q + 1 : q))
		);
	  };
	
	  const handleQuantityMinus = (index) => {
		setQuantities(prevQuantities =>
		  prevQuantities?.map((q, i) => (i === index ? (q > 1 ? q - 1 : q) : q))
		);
	  };
	
	  const handleQuantityChange = (index, e) => {
		const value = parseInt(e.target.value, 10);
		setQuantities(prevQuantities =>
		  prevQuantities?.map((q, i) => (i === index ? (isNaN(value) ? '' : value) : q))
		);
	  };
	
	  const handleQuantityBlur = (index) => {
		setQuantities(prevQuantities =>
		  prevQuantities?.map((q, i) => (i === index && q === '' ? 1 : q))
		);
	  };

  return (
    <div role="main" className="main shop pb-4">
		<div className="container">
			<TopOrderStep active="stepOne" />
			
			{cartData.map((c, index) => (
			<div key={index} className="row pb-4 mb-5">
				<div className="col-lg-8 mb-5 mb-lg-0">
				
				{isLoggedIn && cartData[0].product.length != 0 &&	
					<>		
					<form method="post">
						<div className="table-responsive">
							<table className="shop_table cart">
								<thead>
									<tr className="text-color-dark">
										<th className="product-thumbnail" width="20%">
											&nbsp;
										</th>
										<th className="product-name text-uppercase" width="25%">
											Produit
										</th>
										<th className="product-price text-uppercase" width="15%">
											Prix Unit.
										</th>
										<th className="product-quantity text-uppercase" width="20%">
											Quantité
										</th>
										<th className="product-subtotal text-uppercase text-end" width="20%">
											Prix
										</th>
									</tr>
								</thead>
								
								 
								<tbody>
									{c.product.map((p, index) => (
									<tr key={index} className="cart_table_item">
										<td className="product-thumbnail">
											{console.log(p)}
											<div className="product-thumbnail-wrapper">
												<a onClick={() => handleDeleteProduct(p.cpid, p.cart)} className="product-thumbnail-remove" style={{cursor: "pointer"}} title="Enlever du panier">
													<i className="fas fa-times"></i>
												</a>
												{/* {console.log(p.product)} */}
												<a href={`/product-detail/${p.product.slug}`} className="product-thumbnail-image" title={p.product.slug}>
													<img width="90" height="90" alt={p.product.images[0].alt} className="img-fluid" src={p.product.images[0].image}/>
												</a>
											</div>
										</td>
										<td className="product-name">
											<a href={`/product-detail/${p.product.slug}`} className="font-weight-semi-bold text-color-dark text-color-hover-primary text-decoration-none">{p.product.name}</a>
										</td>
										<td className="product-price">
											<span className="amount font-weight-medium text-color-grey">{p.price}€</span>
										</td>
										<td className="product-quantity">
											<div className="quantity float-none m-0">
												{loadingCartQuantity === true &&
												<>
													<input onClick={() => handleQuantityMinus(index)} type="button" className="minus text-color-hover-light bg-color-hover-primary border-color-hover-primary" value="-"/>
													<input type="text" className="input-text qty text" title="Qty" value={quantities[index] ?? 1} onChange={(e) => handleQuantityChange(index, e)} onBlur={() => handleQuantityBlur(index)}/>
													<input onClick={() => handleQuantityPlus(index)} type="button" className="plus text-color-hover-light bg-color-hover-primary border-color-hover-primary" value="+"/>
												</> 
												}

											</div>
										</td>
										<td className="product-subtotal text-end">
											<span className="amount text-color-dark font-weight-bold text-4">{(p.price * p.quantity).toFixed(2)}€</span>
										</td>
									</tr>
									))}									
								</tbody>								
							</table>							
						</div>
					</form>
					<tr>
						<td colSpan="5">
							<div className="row justify-content-between mx-0">
								<div className="col-md-auto px-0">
									<button onClick={handleChangeQuantity} type="submit" className="btn btn-light btn-modern text-color-dark bg-color-light-scale-2 text-color-hover-light bg-color-hover-primary text-uppercase text-3 font-weight-bold border-0 border-radius-0 btn-px-4 py-3">Confirmer les quantités</button>
								</div>
							</div>
						</td>
					</tr>
					</>
				}
				{isLoggedIn && cartData[0]?.product.length == 0 &&
					<h1 class="font-weight-normal">Votre panier est vide <strong class="font-weight-extra-bold"><Link to={'/products'}>Cliquez ici pour voir tous les produits !</Link></strong></h1>
				}
				</div>
				
				
				<div className="col-lg-4 position-relative">
					<div className="card border-width-3 border-radius-0 border-color-hover-dark" data-plugin-sticky data-plugin-options="{'minWidth': 991, 'containerSelector': '.row', 'padding': {'top': 85}}">
						<div className="card-body">
							<h4 className="font-weight-bold text-uppercase text-4 mb-3">Panier</h4>
							{isLoggedIn && cartData[0]?.product.length != 0 &&
							<table className="shop_table cart-totals mb-4">
								<tbody>
									<tr className="cart-subtotal">
										<td className="border-top-0">
											<strong className="text-color-dark">Sous-total</strong>
										</td>
										<td className="border-top-0 text-end">
											<strong><span className="amount font-weight-medium">{c.total}€</span></strong>
										</td>
									</tr>
									<tr className="shipping">
										<td colSpan="2">
											<strong className="d-block text-color-dark mb-2">Frais</strong>
											<div className="d-flex flex-column">
												<label className="d-flex align-items-center text-color-grey mb-0" htmlFor="shipping_method1">
													Calcul des frais de livraison à la prochaine étape...
												</label>
											</div>
										</td>
									</tr>
									<tr className="total">
										<td>
											<strong className="text-color-dark text-3-5">Total</strong>
										</td>
										<td className="text-end">
											<strong className="text-color-dark"><span className="amount text-color-dark text-5">{c.total}€</span></strong> + Frais
										</td>
									</tr>
								</tbody>
							</table>
							}
							
							{isLoggedIn && cartData[0]?.product.length != 0 &&
								<Link to={`/checkout/${userUid}`} className="btn btn-dark btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3">Prochaine étape<i className="fas fa-arrow-right ms-2"></i></Link>
							}
							{!isLoggedIn &&
								<Link to={'/login'} className="btn btn-dark btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3">Connectez-vous pour passer au payement<i className="fas fa-arrow-right ms-2"></i></Link>
							}
							{cartData[0]?.product.length == 0 &&
								<Link to={'/login'} className="btn btn-dark btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3">Allez au magasin !<i className="fas fa-arrow-right ms-2"></i></Link>
							}
						</div>
					</div>
				</div>
				
			</div>
			))}
		</div>
	</div>
  )
}

export default Cart
