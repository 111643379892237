import React, { useContext, useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import { useAuthStore } from '../../../store/authContext'
import { fetchObject } from '../../../utils/shop';


function Navigation() {
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn());

    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])


    useEffect(() => {
        fetchObject(`/shop/categories`, setCategories, setLoading)
    }, [setCategories, setLoading])
    // console.log(categories)
  return (
    <ul className="nav nav-pills w-100" id="mainNav">
        <li className="dropdown">
            <Link style={{fontSize: "12px", padding: "0px 10px 0px 0px"}} className="dropdown-item dropdown-toggle" to={`/products`}>
                Tous Nos Produits
            </Link>
        </li>
        <li className="dropdown">
            <Link style={{fontSize: "12px", padding: "0px 10px 0px 0px"}} className="dropdown-item dropdown-toggle" to={`/bio/products`}>
                Nos Produits Bio
            </Link>
        </li>
        
        {categories?.map((c, index) => (
            
            <li key={index} className="dropdown">
                {/* {console.log(c.sub_category)} */}
                {c != [] ? 
                <>
                <Link style={{fontSize: "12px", padding: "0px 10px 0px 0px"}} className="dropdown-item dropdown-toggle" to={`/category/${c.slug}/products`}>
                    {c.name}
                </Link>
                <ul className="dropdown-menu">
                    {c.sub_category.map((s, index) => (
                    <li key={index}><Link className="dropdown-item" to={`/${c.slug}/${s.slug}/products`}>{s.name}</Link></li>
                    ))}
                </ul>
                </>
                : ''}
            </li>
        ))}
        
        <li className="dropdown ms-lg-auto">
            {isLoggedIn
                ? <Link style={{fontSize: "12px"}} to={`/logout`}>Déconnexion</Link>
                : <Link style={{fontSize: "12px"}} to={`/login`}>Se connecter</Link>
            }
            
        </li>
        <li className="dropdown">
            {isLoggedIn
                ? null
                : <Link style={{fontSize: "12px"}} to={`/register`}>S'inscrire</Link>
            }
        </li>
    </ul>
);
}

export default Navigation;
