import React from 'react';
import { Link } from 'react-router-dom';
import { UserData } from '../../utils/shop';

function TopOrderStep(props) {
    const userData = UserData();

    const userUid = userData.uid

    // Define dynamic class variables before the return statement
    const stepOneClass = `text-decoration-none ${props.active === "stepOne" ? "text-color-primary" : "text-color-grey-lighten "}`;
    const stepTwoClass = `text-decoration-none ${props.active === "stepTwo" ? "text-color-primary" : "text-color-grey-lighten "}`;
    const stepThreeClass = `text-decoration-none ${props.active === "stepThree" ? "text-color-primary" : "text-color-grey-lighten "}`;

    return (
        <div className="row">
            <div className="col">
                <ul className="breadcrumb font-weight-bold text-6 justify-content-center my-5">
                    <li className="text-transform-none me-2">
                        <Link to={`/cart/${userUid}`} className={`${stepOneClass}`}>Panier</Link>
                    </li>
                    <li className="text-transform-none me-2">
                        <Link to={`/checkout/${userUid}`} className={stepTwoClass}>Adresse de Livraison</Link>
                    </li>
                    <li className="text-transform-none me-2">
                        <Link to={`/checkout/${userUid}`} className={stepThreeClass}>Paiement</Link>
                    </li>
                    {/* <li className="text-transform-none">
                        <Link to={'/order-complete'} className="text-decoration-none text-color-grey-lighten text-color-hover-primary">Commande</Link>
                    </li> */}
                </ul>
            </div>
        </div>
    );
}

export default TopOrderStep;
