import React from 'react';

function NavigationAnnexe() {
  return (
    <ul className="nav nav-pills font-weight-semibold text-2">
      <li className="nav-item dropdown nav-item-left-border d-lg-none">
        <a 
          className="nav-link text-color-default text-color-hover-primary" 
          href="#" 
          role="button" 
          id="dropdownMobileMore" 
          data-bs-toggle="dropdown" 
          aria-haspopup="true" 
          aria-expanded="false"
        >
          More
          <i className="fas fa-angle-down"></i>
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMobileMore">
          <a className="dropdown-item" href="#">About</a>
          <a className="dropdown-item" href="#">Our Stores</a>
          <a className="dropdown-item" href="#">Blog</a>
          <a className="dropdown-item" href="#">Contact</a>
          <a className="dropdown-item" href="#">Help & FAQs</a>
          <a className="dropdown-item" href="#">Track Order</a>
        </div>
      </li>
      <li className="nav-item d-none d-lg-inline-block">
        <a href="#" className="text-decoration-none text-color-default text-color-hover-primary">About</a>
      </li>
      <li className="nav-item d-none d-lg-inline-block">
        <a href="#" className="text-decoration-none text-color-default text-color-hover-primary">Contact</a>
      </li>
      <li className="nav-item d-none d-xl-inline-block nav-item-right-border">
        <a href="#" className="text-decoration-none text-color-default text-color-hover-primary">Help & FAQs</a>
      </li>
    </ul>
  );
}

export default NavigationAnnexe;
