import React from 'react'

function Values() {
  return (
    <div className="container" style={{marginTop: "70px"}}>
        <hr className="my-3 mb-3"/>

        <div className="row pt-3">
            <div className="col-lg-4" data-="fadeInLeftShorter" data--delay="300">
                <div className="feature-box feature-box-style-2">
                    <div className="feature-box-icon">
                        <i className="icons icon-support text-color-primary"></i>
                    </div>
                    <div className="feature-box-info">
                        <h4 className="font-weight-bold mb-2">Customer Support</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing <span className="alternative-font">metus.</span> elit. Quisque rutrum pellentesque imperdiet.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 " data-="fadeInUpShorter">
                <div className="feature-box feature-box-style-2">
                    <div className="feature-box-icon">
                        <i className="icons icon-layers text-color-primary"></i>
                    </div>
                    <div className="feature-box-info">
                        <h4 className="font-weight-bold mb-2">Sliders</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 " data-="fadeInRightShorter" data--delay="300">
                <div className="feature-box feature-box-style-2">
                    <div className="feature-box-icon">
                        <i className="icons icon-menu text-color-primary"></i>
                    </div>
                    <div className="feature-box-info">
                        <h4 className="font-weight-bold mb-2">Buttons</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-lg-4 pb-5">
            <div className="col-lg-4 " data-="fadeInLeftShorter" data--delay="300">
                <div className="feature-box feature-box-style-2">
                    <div className="feature-box-icon">
                        <i className="icons icon-doc text-color-primary"></i>
                    </div>
                    <div className="feature-box-info">
                        <h4 className="font-weight-bold mb-2">HTML5 / CSS3 / JS</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 " data-="fadeInUpShorter">
                <div className="feature-box feature-box-style-2">
                    <div className="feature-box-icon">
                        <i className="icons icon-user text-color-primary"></i>
                    </div>
                    <div className="feature-box-info">
                        <h4 className="font-weight-bold mb-2">Icons</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing <span className="alternative-font">metus.</span> elit. Quisque rutrum pellentesque imperdiet.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 " data-="fadeInRightShorter" data--delay="300">
                <div className="feature-box feature-box-style-2">
                    <div className="feature-box-icon">
                        <i className="icons icon-screen-desktop text-color-primary"></i>
                    </div>
                    <div className="feature-box-info">
                        <h4 className="font-weight-bold mb-2">Lightbox</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Values
