import {create} from 'zustand'
import {mountStoreDevtool} from 'simple-zustand-devtools'


const useAuthStore = create((set, get) => ({

    loading: false,
    setLoading: (loading) => set({loading}),

    userData: null,
    user: () => ({
        id: get().userData?.id || null,
        all: get().userData || null,
        firstname: get().userData?.firstname || null,
    }),
    setUser: (userData) => set({userData: userData}),

    isLoggedIn: () => get().userData !== null
}))

if (import.meta.env.DEV) {
    mountStoreDevtool('Store', useAuthStore)
}

export {useAuthStore}