import { useEffect, useState } from 'react';
import { login, setUser } from '../../utils/auth'
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/authContext';
import { Link } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/');
        }
    }, [isLoggedIn]);

    const resetForm = () => {
        setUsername('');
        setPassword('');
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const { error } = await login(username, password);
        if (error) {
            alert(error);
        } else {
            navigate('/');
            resetForm();
        }
        setIsLoading(false);

    };
    return (
    <div>
        <div className="container py-4">

            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5 mb-5 mb-lg-0">
                    <h2 className="font-weight-bold text-5 mb-0">Connexion</h2>
                    <form onSubmit={handleLogin} id="frmSignIn" method="post" className="needs-validation">
                        <div className="row">
                            <div className="form-group col">
                                <label className="form-label text-color-dark text-3">Adresse électronique <span className="text-color-danger">*</span></label>
                                <input type="text" onChange={(e) => setUsername(e.target.value)} className="form-control form-control-lg text-4" />
                                {/* <label id="-error" className="error">{emailErrorMessage}</label> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <label className="form-label text-color-dark text-3">Mot de passe <span className="text-color-danger">*</span></label>
                                <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control form-control-lg text-4" />
                                {/* <label id="-error" className="error">{passwordErrorMessage}</label> */}
                            </div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="form-group col-md-auto">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="rememberme"/>
                                    <label className="form-label custom-control-label cur-pointer text-2">Remember Me</label>
                                </div>
                            </div>
                            <div className="form-group col-md-auto">
                                <Link className="text-decoration-none text-color-dark text-color-hover-primary font-weight-semibold text-2" to={'/forgot-password'}>Forgot Password?</Link>
                            </div>
                        </div>
                        {/* <label id="-error" className="error">{generalErrorMessage}</label> */}
                        <div className="row">
                            <div className="form-group col">
                                <button type="submit" className="btn btn-dark btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3" data-loading-text="Loading...">Login</button>
                                <div className="divider">
                                    <span className="bg-light px-4 position-absolute left-50pct top-50pct transform3dxy-n50">or</span>
                                </div>
                                <a href="#" className="btn btn-primary-scale-2 btn-modern w-100 text-transform-none rounded-0 font-weight-bold align-items-center d-inline-flex justify-content-center text-3 py-3" data-loading-text="Loading..."><i className="fab fa-facebook text-5 me-2"></i> Login With Facebook</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
    );
};

export default Login;