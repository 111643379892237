import React from 'react'

function Categories() {
  return (
    <div className="container container-xl-custom">
        <hr className="my-5 mb-3"/>
        <div className="row">
            <div className="col">
                <div className="appear-animation-visible">

                <div className="sort-destination-loader-loaded mt-4 pt-2">
                    <div className="row portfolio-list sort-destination" data-sort-id="portfolio" style={{position: "relative", height: "1104.82px"}}>
                        <div className="col-sm-6 col-lg-6 isotope-item websites">
                            <div className="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span className="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                        <span className="thumb-info-wrapper">
                                            <img src="img/projects/project-portfolio-1-1.jpg" className="img-fluid" alt=""/>
                                            <span className="thumb-info-title bg-transparent p-4">
                                                <span className="thumb-info-inner line-height-1 text-4 font-weight-bold">The Desk</span>
                                                <span className="thumb-info-type opacity-6">Websites</span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-6 isotope-item medias">
                            <div className="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span className="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                        <span className="thumb-info-wrapper">
                                            <img src="img/projects/project-portfolio-1-2.jpg" className="img-fluid" alt=""/>
                                            <span className="thumb-info-title bg-transparent p-4">
                                                <span className="thumb-info-inner line-height-1 text-4 font-weight-bold">Porto Watch</span>
                                                <span className="thumb-info-type opacity-6">Medias</span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3 isotope-item brands">
                            <div className="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span className="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                        <span className="thumb-info-wrapper">
                                            <img src="img/projects/project-portfolio-1-3.jpg" className="img-fluid" alt=""/>
                                            <span className="thumb-info-title bg-transparent p-4">
                                                <span className="thumb-info-inner line-height-1 text-4 font-weight-bold">Black Keyboard</span>
                                                <span className="thumb-info-type opacity-6">Brands</span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>

                        

                        <div className="col-sm-6 col-lg-3 isotope-item logos" style={{position: "absolute", left: "1080px", top: "362.823px"}}>
                            <div className="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span className="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                        <span className="thumb-info-wrapper">
                                            <img src="img/projects/project-portfolio-1-4.jpg" className="img-fluid" alt=""/>
                                            <span className="thumb-info-title bg-transparent p-4">
                                                <span className="thumb-info-inner line-height-1 text-4 font-weight-bold">Blue Tag</span>
                                                <span className="thumb-info-type opacity-6">Project Type</span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>									

                        <div className="col-sm-6 col-lg-3 isotope-item brands" style={{position: "absolute", left: "720px", top: "362.823px"}}>
                            <div className="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span className="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                        <span className="thumb-info-wrapper">
                                            <img src="img/projects/project-portfolio-1-5.jpg" className="img-fluid" alt=""/>
                                            <span className="thumb-info-title bg-transparent p-4">
                                                <span className="thumb-info-inner line-height-1 text-4 font-weight-bold">White Car</span>
                                                <span className="thumb-info-type opacity-6">Brands</span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-6 isotope-item logos">
                            <div className="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span className="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                        <span className="thumb-info-wrapper">
                                            <img src="img/projects/project-portfolio-1-6.jpg" className="img-fluid" alt=""/>
                                            <span className="thumb-info-title bg-transparent p-4">
                                                <span className="thumb-info-inner line-height-1 text-4 font-weight-bold">Porto Card</span>
                                                <span className="thumb-info-type opacity-6">Logos</span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3 isotope-item brands">
                            <div className="portfolio-item">
                                <a href="portfolio-single-wide-slider.html">
                                    <span className="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                                        <span className="thumb-info-wrapper">
                                            <img src="img/projects/project-portfolio-1-7.jpg" className="img-fluid" alt=""/>
                                            <span className="thumb-info-title bg-transparent p-4">
                                                <span className="thumb-info-inner line-height-1 text-4 font-weight-bold">Bicycle Trends</span>
                                                <span className="thumb-info-type opacity-6">Brands</span>
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Categories
