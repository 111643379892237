import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../store/authContext';

import DataTitle from './header/top-header/DataTitle';
import NavigationAnnexe from './header/top-header/NavigationAnnexe';
import SocialNetwork from './header/top-header/SocialNetwork';

import Logo from './header/mid-header/Logo';
import SearchBar from './header/mid-header/SearchBar';
import PhoneNumber from './header/mid-header/PhoneNumber';
import UserAction from './header/mid-header/UserAction';

import Navigation from './header/bot-header/Navigation';

function Header() {
  const [isLoggedIn, user] = useAuthStore((state) => [
    state.isLoggedIn,
    state.user,
  ]);
  return (
    <header
      id="header"
      data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyStartAt': 135, 'stickySetTop': '-135px', 'stickyChangeLogo': true}"
    >
      <div className="header-body header-body-bottom-border-fixed box-shadow-none border-top-0">
        <div className="header-top header-top-small-minheight header-top-simple-border-bottom">
          <div className="container">
            <div className="header-row justify-content-between">
              {/* You can activate this component to have the Header Title Data */}
              {/* <DataTitle /> */}
              <div className="header-column justify-content-end col-auto px-0">
                <div className="header-row">
                  <nav className="header-nav-top">
                    <NavigationAnnexe />
                    <SocialNetwork />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-container container">
          <div className="header-row py-2">
            <div className="header-column w-100">
              <div className="header-row justify-content-between">
                <Logo />
                <SearchBar />
                {/* <PhoneNumber /> */}
                <UserAction />
              </div>
            </div>
          </div>
        </div>

        <div className="header-nav-bar header-nav-bar-top-border bg-light">
          <div className="header-container container">
            <div className="header-row">
              <div className="header-column">
                <div className="header-row justify-content-end">
                  <div
                    className="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border justify-content-start"
                    data-sticky-header-style="{'minResolution': 991}"
                    data-sticky-header-style-active="{'margin-left': '105px'}"
                    data-sticky-header-style-deactive="{'margin-left': '0'}"
                  >
                    <div className="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-3 header-nav-main-sub-effect-1 w-100">
                      <nav className="collapse w-100">
                        <Navigation />
                      </nav>
                    </div>
                    <button
                      className="btn header-btn-collapse-nav"
                      data-bs-toggle="collapse"
                      data-bs-target=".header-nav-main nav"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
