import React from 'react'
import { useState, useEffect } from 'react'
import { fetchData, fetchDataForUser } from '../../utils/shop'
import { UserData } from '../../utils/shop'
import {COUNTRIES, EUROPEEN_COUNTRIES}from '../../meta/countries'
import { useForm } from 'react-hook-form'
import useAxios from '../../utils/useAxios'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import TopOrderStep from './TopOrderStep'

function Checkout() {
    const navigate = useNavigate();
    const userData = UserData()
    const request = useAxios()
	const userUid = userData.uid
	const userId = userData.id
	
	const [orderData, setOrderData] = useState([])
    const [profileData, setProfileData] = useState([])
    const pd = profileData[0]

	const [loadingOrderData, setLoadingOrderData] = useState(false)
	const [loadingProfilerData, setLoadingProfilerData] = useState(false)

	useEffect(() => {
		fetchDataForUser(`auth/profile/${userData.id}/${userData.profile_pid}`, setProfileData, setLoadingProfilerData)
        setLoadingProfilerData(true)
	}, [])

	useEffect(() => {	
        fetchDataForUser(`order/cart/${userUid}`, setOrderData, setLoadingOrderData)
        setLoadingOrderData(true)
	}, [])

    if (orderData[0]?.product?.length === 0) {
        navigate('/');
    }    

    // Address Form
    const formAddress = useForm()
	const {register, handleSubmit, formState} = formAddress
	const {errors} = formState

    useEffect(() => {
		if (profileData.length > 0) {
            formAddress.reset({
			first_name: pd.first_name || '',
			last_name: pd.last_name || '',
			email: pd.user?.email || '',
			address: pd.address || '',
			country: pd.country || '',
			city: pd.city || '',
			zip: pd.zip || '',
		  });
		}
	}, [profileData]);


    // Coupon handler
    const [couponCode, setCouponCode] = useState("")
    const [shippingFee, setShippingFee] = useState(orderData[0]?.total - orderData[0]?.sub_total + 5)
    const [couponResponse, setCouponResponse] = useState([])

    useEffect(() => {
        if (orderData && orderData[0]?.total && orderData[0]?.sub_total) {
            setShippingFee(orderData[0].total - orderData[0].sub_total + 5);
        }
    }, [orderData]);

    const handleCouponCode = async () => {
        const payload = {
            "user_uid": userUid,
            "code": couponCode,
        }

        try {
            const response = await request.post(`/order/checkout/coupon/${userUid}`, payload)
            setCouponResponse(response.data)
            if (response.status === 200 && couponResponse.shipping_fee === true) {
                console.error("Success");
            }

            window.location.reload()


        } catch (error) {
            if (response.status === 400) {
                console.error("Checkout failed", error);
            } else {
                console.error("Error fetching data", error);
            }
        }
    };

    const [country, setCountry] = useState(pd?.country || '')

    const handleCountryChange = (e) => {
        setCountry(e.target.value)
    }

    useEffect(() => {
        setCountry(country ||  pd?.country)
    }, [country, pd?.country])


    // Stripe checkout
    const onSubmit = async (data, e) => {
        e.preventDefault()
        try {
            const response = await request.post(`/order/checkout/${userUid}`, data);
            if (response.status === 200) {
                const stripeResponse = await request.post(`/order/stripe-checkout/${userUid}`);
                window.location.href = stripeResponse.data.url;
            }
        } catch (error) {
            console.error("Checkout failed", error);
        }
    };

  return (
    <div role="main" className="main shop pb-4">
        <div className="container">
        <TopOrderStep active="stepTwo" />

            <div className="row coupon-form-wrapper collapse mb-5">
                <div className="col">
                    <div className="card border-width-3 border-radius-0 border-color-hover-dark">
                        <div className="card-body">
                            <form role="form" method="post">
                                <div className="d-flex align-items-center">
                                    <input type="text" className="form-control h-auto border-radius-0 line-height-1 py-3" name="couponCode" placeholder="Coupon Code" required />
                                    <button type="submit" className="btn btn-light btn-modern text-color-dark bg-color-light-scale-2 text-color-hover-light bg-color-hover-primary text-uppercase text-3 font-weight-bold border-0 border-radius-0 ws-nowrap btn-px-4 py-3 ms-2">Apply Coupon</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <form role="form" className="needs-validation" method="post" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-7 mb-4 mb-lg-0">
                        <h2 className="text-color-dark font-weight-bold text-5-5 mb-3">Adresse de livraison</h2>

                        {profileData.map((p, index) => (
                        <div key={index}>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="form-label">Prénom <span className="text-color-danger">*</span></label>
                                <input type="text" className="form-control h-auto py-2" {...register('first_name')} required />
                            </div>
                            <div className="form-group col-md-6">
                                <label className="form-label">Nom <span className="text-color-danger">*</span></label>
                                <input type="text" className="form-control h-auto py-2" {...register('last_name')} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <label className="form-label">Pays <span className="text-color-danger">*</span></label>
                                <div className="custom-select-1">
                                    <select className="form-select form-control h-auto py-2" {...register('country')} onChange={handleCountryChange} required>
                                    <option hidden>Pays</option>
                                    {COUNTRIES.map((country) => (
                                        <option key={country.code} value={country.name}>
                                        {country.name}
                                        </option>
                                    ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <label className="form-label">Adresse <span className="text-color-danger">*</span></label>
                                <input type="text" className="form-control h-auto py-2" {...register('address')} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <label className="form-label">Ville <span className="text-color-danger">*</span></label>
                                <input type="text" className="form-control h-auto py-2" {...register('city')} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <label className="form-label">Code postal <span className="text-color-danger">*</span></label>
                                <input type="text" className="form-control h-auto py-2" {...register('zip')} required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <label className="form-label">Email <span className="text-color-danger">*</span></label>
                                <input type="email" className="form-control h-auto py-2" {...register('email')} required />
                            </div>
                        </div>
                        </div>
                        ))}
                        
                    </div>
                    <div className="col-lg-5 position-relative">
                        <div className="card border-width-3 border-radius-0 border-color-hover-dark" data-plugin-sticky data-plugin-options="{'minWidth': 991, 'containerSelector': '.row', 'padding': {'top': 85}}">
                            <div className="card-body">
                                <h4 className="font-weight-bold text-uppercase text-4 mb-3">Votre Panier</h4>
                                <table className="shop_table cart-totals mb-3">
                                    <tbody>
                                        <tr>
                                            <td colSpan="2" className="border-top-0">
                                                <strong className="text-color-dark">Vos produits</strong>
                                            </td>
                                        </tr>
                                        {orderData[0]?.product.map((p, index) => (
                                        <tr key={index}>
                                            <td>
                                                <strong className="d-block text-color-dark line-height-1 font-weight-semibold">{p.product.name} <span className="product-qty">X{p.quantity}</span></strong>
                                                {p.weight !== undefined || "" && <span className="text-1">Poids : {p.weight}</span>}
                                            </td>
                                            <td className="text-end align-top">
                                                <span className="amount font-weight-medium text-color-grey">{p.price}€</span>
                                            </td>
                                        </tr>
                                        ))}
                                        
                                        <tr className="cart-subtotal">
                                            <td className="border-top-0">
                                                <strong className="text-color-dark">Frais de livraison</strong>
                                            </td>
                                            <td className="border-top-0 text-end">
                                                <strong><span className="amount font-weight-medium">
                                                    {orderData[0]?.shipping_fee}
                                                </span></strong>
                                            </td>
                                        </tr>
                                        <tr className="cart-subtotal">
                                            <td className="border-top-0">
                                                <strong className="text-color-dark">Subtotal</strong>
                                            </td>
                                            <td className="border-top-0 text-end">
                                                <strong><span className="amount font-weight-medium">{orderData[0]?.sub_total}€</span></strong>
                                            </td>
                                        </tr>
                                        
                                        <tr className="total">
                                            <td>
                                                <strong className="text-color-dark text-3-5">Total</strong>
                                            </td>
                                            <td className="text-end">
                                                <strong className="text-color-dark"><span className="amount text-color-dark text-5">{orderData[0]?.total}€</span></strong>

                                            </td>
                                        </tr>
                                        <tr className="payment-methods">
                                            <td colSpan="2">
                                                <strong className="d-block text-color-dark mb-2">Payment Methods</strong>

                                                <div className="d-flex flex-column">
                                                    <label className="d-flex align-items-center text-color-grey mb-0" htmlFor="payment_method1">
                                                        <input id="payment_method1" type="radio" className="me-2" name="payment_method" value="cash-on-delivery" defaultChecked />
                                                        Stripe
                                                    </label>
                                                    {/* <label className="d-flex align-items-center text-color-grey mb-0" htmlFor="payment_method2">
                                                        <input id="payment_method2" type="radio" className="me-2" name="payment_method" value="paypal" />
                                                        PayPal
                                                    </label> */}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                Vous allez être dirigé vers un portail de paiement de Stripe aprés avoir confirmé votre commande.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button type="submit" className="btn btn-dark btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3">Confirmer <i className="fas fa-arrow-right ms-2"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="row justify-content-between mx-0">
                <div className="col-md-auto px-0 mb-3 mb-md-0">
                    <div className="d-flex align-items-center">
                        <input onChange={(e) => setCouponCode(e.target.value)} type="text" className="form-control h-auto border-radius-0 line-height-1 py-3" placeholder="CODE" />
                        <button onClick={handleCouponCode} className="btn btn-light btn-modern text-color-dark bg-color-light-scale-2 text-color-hover-light bg-color-hover-primary text-uppercase text-3 font-weight-bold border-0 border-radius-0 ws-nowrap btn-px-4 py-3 ms-2">Appliquer le coupon</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Checkout
