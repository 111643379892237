import axios from 'axios'
import { API_BASE_URL } from './constants'

const http = axios.create({
    baseURL: API_BASE_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

export default http