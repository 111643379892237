import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchData, fetchDataForUser, UserData } from '../../utils/shop'
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete'
import http from '../../utils/axios'
import {COUNTRIES} from '../../meta/countries'
import { useForm } from 'react-hook-form'
import SideProfile from '../order/SideProfile'


function Profile() {

	const userData = UserData()

	const [profileData, setProfileData] = useState([])
	const [loading, setLoading] = useState(true)

	const pd = profileData[0]

	useEffect(() => {
		if (userData && userData.id && userData.profile_pid) {
			fetchDataForUser(`auth/profile/${userData.id}/${userData.profile_pid}`, setProfileData, setLoading);
		}
		}, []);

	useEffect(() => {
		if (profileData.length > 0) {
		  form.reset({
			// image: pd.image || '',
			first_name: pd.first_name || '',
			last_name: pd.last_name || '',
			gender: pd.gender || '',
			email: pd.user?.email || '',
			address: pd.address || '',
			country: pd.country || '',
			city: pd.city || '',
			zip: pd.zip || '',
		  });
		}
	  }, [profileData]);

	const form = useForm()
	const {register, handleSubmit, formState} = form
	const {errors} = formState
	
	const onSubmit = async (data) => {

		const formData = new FormData();

		// if (data.image) {
		// 	formData.append('image', data.image);
		// }

		formData.append('first_name', data.first_name);
		formData.append('last_name', data.last_name);
		formData.append('gender', data.gender);
		formData.append('email', data.email);
		formData.append('address', data.address);
		formData.append('country', data.country);
		formData.append('city', data.city);
		formData.append('zip', data.zip);

		await http.patch(`auth/profile/${userData.id}/${userData.profile_pid}`, formData)
		.then(response => {
			console.log(response.data)
			window.location.reload();
		})
		.catch(error => {
			console.log(error)
		})
		console.log('submited : ', data.image)
	}
	
	return (
    <div role="main" className="main">
		<div className="container pt-5 pb-2">
			<form role="form" className="needs-validation" encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} noValidate>
				<div className="row pt-2">

					<SideProfile />

					<div className="col-lg-9">
						<div className="row">
                            <div className="col">
                                <h4 className="mb-0">Mon Profile</h4>
                                <hr className="solid mt-3"/>
                            </div>
                        </div>
						<div className="form-group row">
							<label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 required">Prénom</label>
							<div className="col-lg-9">
								<input className="form-control text-3 h-auto py-2" type="text" {...register('first_name')}/>
							</div>
						</div>
						<div className="form-group row">
							<label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 required">Nom</label>
							<div className="col-lg-9">
								<input className="form-control text-3 h-auto py-2" type="text" {...register('last_name')}/>
							</div>
						</div>
						<div className="row" style={{marginLeft: '204px'}}>
							<div className="form-group col0">
								<div className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 form-check-inline">
									<label className="form-check-label">
										<input className="form-check-input" type="radio" {...register('gender')} id="tabContent12Radio1" value="m"/> M.
									</label>
								</div>
								<div className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 form-check-inline">
									<label className="form-check-label">
										<input className="form-check-input" type="radio" {...register('gender')} id="tabContent12Radio2" value="mme"/> Mme.
									</label>
								</div>
								<div className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 form-check-inline">
									<label className="form-check-label">
										<input className="form-check-input" type="radio" {...register('gender')} id="tabContent12Radio3" value="x"/> X.
									</label>
								</div>
							</div>
						</div>
						<div className={`form-group row ${errors.email && 'has-danger'}`}>
							<label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2">Email<span className="text-color-danger">*</span></label>
							<div className="col-lg-9">
								<input className={`form-control text-3 h-auto py-2 ${errors.email && 'is-invalid'}`} type="email" {...register('email', {pattern: {value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/, message: 'Veuillez fournir un format d\'email valide'}, required: {value:true, message: 'Veuillez fournir un e-mail'}})}/>
								{errors && <label id="-error" className="error">{errors.email?.message}</label>}
							</div>
						</div>
						
						<div className="form-group row">
							<label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2">Adresse</label>
							<div className="col-lg-6"> 
								<input className="form-control text-3 h-auto py-2" type="text" placeholder='Adresse' {...register('address')}/>
							</div>
							<div className="col-lg-3">
								<select className="form-select form-control h-auto py-2" {...register('country')}>
								<option hidden>Pays</option>
								{COUNTRIES?.map((country) => (
									<option key={country.code} value={country.name}>
									{country.name}
									</option>
								))}
								</select>
							</div>
						</div>
						<div className="form-group row">
							<label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2"></label>
							<div className="col-lg-6">
								<input className="form-control text-3 h-auto py-2" type="text" {...register('city')} placeholder="Ville"/>
							</div>
							<div className="col-lg-3">
								<input className="form-control text-3 h-auto py-2" type="text" {...register('zip')} placeholder="Code postal"/>
							</div>
						</div>
						<div className="form-group row">
							<div className="form-group col-lg-9">

							</div>
							<div className="form-group col-lg-3">
								<button className="btn btn-primary btn-modern float-end">Sauvegarder</button>
							</div>
						</div>
						</div>
					</div>
			</form>
		</div>
	</div>
  )
}

export default Profile
