import React from 'react'

function Banner() {
  return (
    <div className="owl-carousel-light-init-fadeIn owl-theme manual dots-inside dots-horizontal-center show-dots-hover nav-inside nav-inside-plus nav-dark nav-md nav-font-size-md show-nav-hover mb-0" data-plugin-options="{'autoplayTimeout': 7000}" data-dynamic-height="['670px','670px','670px','550px','500px']" style={{height: "570px"}}>
        <div className="owl-stage-outer">
            <div className="owl-stage">
                <div className="owl-item position-relative" style={{backgroundImage: "url('/img/slides/slide-bg-6.jpg')", backgroundSize: "cover", backgroundPosition: "center", height: "600px"}}>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Banner
