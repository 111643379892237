import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import {register} from '../../utils/auth'
import {useAuthStore} from '../../store/authContext'

function Register() {

  const navigate = useNavigate()
  const isLoggedIn = useAuthStore(state => state.isLoggedIn())


  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn])


  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [codeError, setCodeError] = useState(0)
  const [emailClassNameError, setEmailClassNameError] = useState(false)
  const [passwordClassNameError, setPasswordClassNameError] = useState(false)
  const [password2ClassNameError, setPassword2ClassNameError] = useState(false)


  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
  const [password2ErrorMessage, setPassword2ErrorMessage] = useState('')
  const [generalErrorMessage, setGeneralErrorMessage] = useState('')

  function isValidPassword(password) {
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinimumLength = password.length >= 8;
  
    if (hasSpecialCharacter && hasNumber && hasMinimumLength) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (email) {
      setEmailClassNameError(false)
      setEmailErrorMessage('')
    }

    if (password) {
      setPasswordClassNameError(false)
      setPasswordErrorMessage('')
    }
  }, [email, password])

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Clear previous error states
    setEmailClassNameError(false);
    setPasswordClassNameError(false);
    setPassword2ClassNameError(false);
    setGeneralErrorMessage('');

    // Validation checks
    if (!email) {
        setEmailClassNameError(true);
        setEmailErrorMessage('Veuillez renseigner votre E-mail');
        setIsLoading(false);
        return;
    }

    if (!password) {
        setPasswordClassNameError(true);
        setPasswordErrorMessage('Veuillez renseigner votre mot de passe');
        setIsLoading(false);
        return;
    }

    if (!password2) {
        setPassword2ClassNameError(true);
        setPassword2ErrorMessage('Veuillez confirmer votre mot de passe');
        setIsLoading(false);
        return;
    }

    if (password !== password2) {
        setPasswordClassNameError(true);
        setPassword2ClassNameError(true);
        setGeneralErrorMessage('Vos mots de passe ne correspondent pas');
        setIsLoading(false);
        return;
    }

    if (!isValidPassword(password)) {
        setPasswordClassNameError(true);
        setGeneralErrorMessage('Votre mot de passe doit contenir au minimum 8 caractères, 1 caractère spécial, et 1 caractère numérique');
        setIsLoading(false);
        return;
    }

    // If all validations pass, make the API call
    try {
        const { data, error } = await register(email, password, password2);

        // Handle API response errors
        if (error) {
            switch (error) {
                case 400:
                    setGeneralErrorMessage('Erreur dans la demande');
                    break;
                case 404:
                    navigate('/not-found');
                    break;
                default:
                    navigate('/login');
            }
        } else {
            // Navigate to login on success
            navigate('/login');
        }

    } catch (e) {
        console.log(e);
        setGeneralErrorMessage('Une erreur est survenue. Veuillez réessayer.');
    } finally {
        setIsLoading(false);
    }
};

  return (
    <div>
        <div className="container py-4">

					<div className="row justify-content-center">
						<div className="col-md-6 col-lg-5 mb-5 mb-lg-0">
							<h2 className="font-weight-bold text-5 mb-0">Inscription</h2>
							<form onSubmit={handleRegister} id="frmSignIn" method="post" className="needs-validation">
								<div className="row">
									<div className="form-group col">
										<label className="form-label text-color-dark text-3">Adresse électronique <span className="text-color-danger">*</span></label>
										<input type="text" onChange={(e) => setEmail(e.target.value)} className={emailClassNameError ? 'form-control form-control-lg text-4 is-invalid' : 'form-control form-control-lg text-4'} />
                    <label id="-error" className="error">{emailErrorMessage}</label>
									</div>
								</div>
								<div className="row">
									<div className="form-group col">
										<label className="form-label text-color-dark text-3">Mot de passe <span className="text-color-danger">*</span></label>
										<input type="password" onChange={(e) => setPassword(e.target.value)} className={passwordClassNameError ? 'form-control form-control-lg text-4 is-invalid' : 'form-control form-control-lg text-4'} />
                    <label id="-error" className="error">{passwordErrorMessage}</label>
									</div>
								</div>
                <div className="row">
									<div className="form-group col">
										<label className="form-label text-color-dark text-3">Confirmation de mot de passe <span className="text-color-danger">*</span></label>
										<input type="password" onChange={(e) => setPassword2(e.target.value)} className={password2ClassNameError ? 'form-control form-control-lg text-4 is-invalid' : 'form-control form-control-lg text-4'} />
                    <label id="-error" className="error">{password2ErrorMessage}</label>
									</div>
								</div>
                <div className="row">
									<div className="form-group col">
										<p className="text-2 mb-2">Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our <a href="#" className="text-decoration-none">privacy policy.</a></p>
									</div>
								</div>
                <label id="-error" className="error">{generalErrorMessage}</label>
								<div className="row">
									<div className="form-group col">
										<button type="submit" className="btn btn-dark btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3" data-loading-text="Loading...">S'inscrire</button>
									</div>
								</div>
							</form>
						</div>
					</div>

				</div>
    </div>
  )
}

export default Register
