import React from 'react'
import { Link } from 'react-router-dom'
import {UserData } from '../../utils/shop'


function SideProfile() {
  const userData = UserData()
  return (
    <div className="col-lg-3 mt-4 mt-lg-0">
        <aside className="sidebar mt-2" id="sidebar">
            <ul className="nav nav-list flex-column mb-5">
                <li className="nav-item"><Link className="nav-link text-3 text-dark active" to={`/client-order/${userData.profile_pid}`}>Mes commandes</Link></li>
                <li className="nav-item"><a className="nav-link text-3" href="#">Les produits commandés</a></li>
                <li className="nav-item"><a className="nav-link text-3" href="#">Wishlist</a></li>
            </ul>
        </aside>
    </div>
  )
}

export default SideProfile
