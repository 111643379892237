import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchDataForUser, UserData } from '../../utils/shop'
import SideProfile from './SideProfile'

const MONTH_FORMAT = {
    1: 'JAN',
    2: 'FEV',
    3: 'MAR',
    4: 'APR',
    5: 'MAI',
    6: 'JUI',
    7: 'JUL',
    8: 'AOU',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC',
}


function ClientOrder() {
    const [clientOrder, setClientOrder] = useState([])
    const [loading, setLoading] = useState(false)
    const userData = UserData()
    const userPid = userData.profile_pid

    useEffect(() => {
        fetchDataForUser(`/order/order-client/${userPid}`, setClientOrder, setLoading)
    }, [])

    const clientOrderFormated = clientOrder.flat();


    console.log(clientOrderFormated)

  return (
    <div role="main" className="main">
		<div className="container pt-5 pb-2">
            <div id="examples" className="row pt-2">
                <div className="row">
                    <SideProfile />
                    <div className="col pb-4">
                        <div className="row">
                            <div className="col">
                                <h4 className="mb-0">Mes commandes</h4>
                                <hr className="solid mt-3"/>
                            </div>
                        </div>
                        <div className="row">
                            {clientOrderFormated.map((co, indexCo) => (
                            <div className="col-lg-6 mb-4 mb-lg-4" key={indexCo}>
                                <div className="recent-posts">
                                    <article className="post">
                                        <div className="row">
                                            <div className="col-auto pe-0">
                                                <div className="post-date">
                                                    <span className="day text-color-dark font-weight-extra-bold">{co.date.split('/')[0]}</span>
                                                    <span className="month">{MONTH_FORMAT[co.date.split('/')[1]]}</span>
                                                    <span className="year">{co.date.split('/')[2]}</span>
                                                </div>
                                            </div>
                                            <div className="col ps-1">
                                                <h4 className="line-height-3"><a href="blog-post.html" className="text-decoration-none">{co.status}</a></h4>
                                                <li><strong>Total : {co.cart.total}€</strong></li>
                                                <li><strong>{co.cart.product.length} - Produits</strong></li>
                                                {/* <li><strong>Quantité : {co.cart.product.reduce((total, p) => {return total + p.quantity}, 0)} </strong></li> */}
                                                <div className="col-lg-10">
                                                    {/* <ul className="list-unstyled">
                                                    <li><strong>Produits achetés :</strong></li>

                                                        {co.cart.product.slice(0, 3).map((p, indexProduct) => (
                                                            <div key={indexProduct}>
                                                            <li >- {p.product.name}</li>
                                                            </div>
                                                        ))}
                                                        
                                                    </ul> */}
                                                </div>
                                                <Link to={`/client-order/${co.profile.pid}/${co.coid}`} className="read-more text-color-dark font-weight-bold text-2">Voir la commande N°{co.coid.substring(0, 7)}...<i className="fas fa-chevron-right text-1 ms-1"></i></Link>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            ))}
                            
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ClientOrder
