import React, { useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import { fetchObject } from '../../utils/shop'

function Products() {
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const category = useParams().category
    const subCategory = useParams().subCategory
    const tag = useParams().tag

    useEffect(() => {
        if (category && subCategory) {
            fetchObject(`shop/${category}/${subCategory}/products`, setProducts, setLoading);
        } else if (category && !subCategory) {
            fetchObject(`shop/category/${category}/products`, setProducts, setLoading);
        } else if (tag) {
            fetchObject(`shop/tag/${tag}/products`, setProducts, setLoading);
        } else {
            fetchObject(`shop/products`, setProducts, setLoading);
        }
    }, [category, subCategory, tag]);

    // console.log('products', products)

return (
    <div role="main" className="main shop pt-4">
        
        <div className="container">
            <div className="row products product-thumb-info-list" data-plugin-masonry data-plugin-options="{'layoutMode': 'fitRows'}">
            {products?.map((p, index) => (
                
                <div key={index} className="col-12 col-sm-6 col-lg-3" >
                    {/* <Link className='text-decoration-none' to={`/product-detail/${p.slug}`}> */}
                    <div className="product mb-0">
                        <div className="product-thumb-info border-0 mb-3">

                            <div className="product-thumb-info-badges-wrapper">
                                {p?.tag && <span className="badge badge-ecommerce badge-success">{p.tag.name}</span>}
                                {p?.discount != 0 && <span className="badge badge-ecommerce badge-danger">{Math.round(p.discount)} % de réduction</span>}
                            </div>

                            <div className="addtocart-btn-wrapper">
                                <a href="shop-cart.html" className="text-decoration-none addtocart-btn" title="Add to Cart">
                                    <i className="icons icon-bag"></i>
                                </a>
                            </div>
                            { p.images &&
                            <Link to={`/product-detail/${p?.slug}`}>
                                <div className="product-thumb-info-image product-thumb-info-image-effect">
                                    <img alt={p?.images[0]?.alt} className="img-fluid" src={p?.images[0]?.image}/>
                                    <img alt={p?.images[0]?.alt} className="img-fluid" src={p?.images[0]?.image}/>
                                </div>
                            </Link>
                            }
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>
                                <div className="d-block text-uppercase line-height-1 text-0 mb-1">
                                    <Link
                                        to="#"
                                        className="text-decoration-none text-color-default text-color-hover-primary"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {p?.category?.name}
                                    </Link>
                                    {/* {' > '} */}
                                    {p?.sub_category?.name && (
                                        <Link
                                        to="#"
                                        className="text-decoration-none text-color-default text-color-hover-primary"
                                        onClick={(e) => e.preventDefault()}
                                        >
                                        {' > '}
                                        {p?.sub_category?.name}
                                        </Link>
                                    )}
                                </div>
                                <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0"><Link className="text-color-dark text-color-hover-primary" to={`/product-detail/${p.slug}`}>{p.name}</Link></h3>
                            </div>
                            <a href="#" className="text-decoration-none text-color-default text-color-hover-dark text-4"><i className="far fa-heart"></i></a>
                        </div>
                        <p className="price text-5 mb-3">
                            <span className="sale text-color-dark font-weight-semi-bold">{p.discount_price ? p.discount_price : p.price} €</span>
                            <span className="amount">{p.discount_price ? p.price : ''}</span>
                        </p>
                    </div>
                    {/* </Link> */}
                </div>
            ))}
            </div>

            <div className="row mt-4">
                <div className="col">
                    <ul className="pagination float-end">
                        <li className="page-item"><a className="page-link" href="#"><i className="fas fa-angle-left"></i></a></li>
                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item"><a className="page-link" href="#"><i className="fas fa-angle-right"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Products
