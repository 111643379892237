import React, {useState, useEffect} from 'react'

function ForgotPassword() {
    const [email, setEmail] = useState('');
  return (
    <div>
        <div className="container py-4">

            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5 mb-5 mb-lg-0">
                    <h2 className="font-weight-bold text-5 mb-0">Mot de passe oublié</h2>
                    <form id="frmSignIn" method="post" className="needs-validation">
                        <div className="row">
                            <div className="form-group col">
                                <label className="form-label text-color-dark text-3">Adresse électronique <span className="text-color-danger">*</span></label>
                                <input type="text" onChange={(e) => setUsername(e.target.value)} className="form-control form-control-lg text-4" />
                                {/* <label id="-error" className="error">{emailErrorMessage}</label> */}
                            </div>
                        </div>
                        {/* <label id="-error" className="error">{generalErrorMessage}</label> */}
                        <div className="row">
                            <div className="form-group col">
                                <button type="submit" className="btn btn-dark btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3" data-loading-text="Loading...">Envoyer un email de réinitilaisation</button>                               
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
  )
}

export default ForgotPassword
