import React, { useEffect, useState } from 'react'
import { fetchDataForUser } from '../../utils/shop'
import { useParams } from 'react-router-dom'

function ClientOrderDetail() {
    const [clientOrderDetail, setClientOrderDetail] = useState([])
    const [loading, setLoading] = useState(false)

    const params = useParams()

    const pid = params['profile_pid']
    const coid = params['checkout_coid']

    useEffect(() => {
        fetchDataForUser(`/order/order-client/${pid}/${coid}`, setClientOrderDetail, setLoading)
    }, [])
    console.log(clientOrderDetail)
  return (
    <div role="main" className="main shop pb-4">
        <div className="container">

            <div className="row justify-content-center">
                <div className="col-lg-8">
                    {clientOrderDetail.map((cod, indexCod) => (
                    <div key={indexCod}>
                        <div className="d-flex flex-column flex-md-row justify-content-between py-3 px-4 my-4">
                            <div className="text-center">
                                <span>
                                    N° de Commande <br/>
                                    <strong className="text-color-dark">31891</strong>
                                </span>
                            </div>
                            <div className="text-center mt-4 mt-md-0">
                                <span>
                                    Date de commande <br/>
                                    <strong className="text-color-dark">{cod.date}</strong>
                                </span>
                            </div>
                            <div className="text-center mt-4 mt-md-0">
                                <span>
                                    Email <br/>
                                    <strong className="text-color-dark">{cod.email}</strong>
                                </span>
                            </div>
                            <div className="text-center mt-4 mt-md-0">
                                <span>
                                    N° de Suivi <br/>
                                    <strong className="text-color-dark">{"n suivi"}</strong>
                                </span>
                            </div>
                        </div>
                        <div className="card border-width-3 border-radius-0 border-color-hover-dark mb-4">
                            <div className="card-body">
                                <h4 className="font-weight-bold text-uppercase text-4 mb-3">Commande - N° {cod.coid}</h4>
                                <table className="shop_table cart-totals mb-0">
                                    <tbody>
                                        <tr>
                                            <td colSpan="2" className="border-top-0">
                                                <strong className="text-color-dark">Produits achetés :</strong>
                                            </td>
                                        </tr>
                                        {cod.cart.product.map((p, indexP) => (                            
                                        <tr key={indexP}>
                                            <td className="border-top-0 pt-0">
                                                <strong className="d-block text-color-dark line-height-1 font-weight-semibold">{p.product.name} <span className="product-qty">x{p.quantity}</span></strong>
                                                <span className="text-1">{p.weight === "" ? "" : p.weight}</span>
                                                </td>
                                            <td className="border-top-0 text-end align-top pt-0">
                                                <span className="amount font-weight-medium text-color-grey">{p.price}€</span>
                                            </td>
                                        </tr>
                                        ))}
                                        <tr className="cart-subtotal">
                                            <td className="border-top-0">
                                                <strong className="text-color-dark">Subtotal</strong>
                                            </td>
                                            <td className="border-top-0 text-end">
                                                <strong><span className="amount font-weight-medium">{cod.cart.total}€</span></strong>
                                            </td>
                                        </tr>
                                        <tr className="shipping">
                                            <td className="border-top-0">
                                                <strong className="text-color-dark">Shipping</strong>
                                            </td>
                                            <td className="border-top-0 text-end">
                                                <strong><span className="amount font-weight-medium">Free Shipping</span></strong>
                                            </td>
                                        </tr>
                                        <tr className="total">
                                            <td>
                                                <strong className="text-color-dark text-3-5">Total</strong>
                                            </td>
                                            <td className="text-end">
                                                <strong className="text-color-dark"><span className="amount text-color-dark text-5">$431</span></strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <h2 className="text-color-dark font-weight-bold text-5-5 mb-1">Billing Address</h2>
                                <ul className="list list-unstyled text-2 mb-0">
                                    <li className="mb-0">John Doe Junior</li>
                                    <li className="mb-0">Street Name, City</li>
                                    <li className="mb-0">State AL 85001</li>
                                    <li className="mb-0">123 456 7890</li>
                                    <li className="mt-3 mb-0">abc@abc.com</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <h2 className="text-color-dark font-weight-bold text-5-5 mb-1">Shipping Address</h2>
                                <ul className="list list-unstyled text-2 mb-0">
                                    <li className="mb-0">John Doe Junior</li>
                                    <li className="mb-0">Street Name, City</li>
                                    <li className="mb-0">State AL 85001</li>
                                    <li className="mb-0">123 456 7890</li>
                                    <li className="mt-3 mb-0">abc@abc.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    )
}

export default ClientOrderDetail
