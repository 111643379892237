import { useState, useEffect } from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'

import MainWrapper from './layout/MainWrapper'
import PrivateRoute from './layout/PrivateRoute'

import { useAuthStore } from './store/authContext'
import { useCartStore } from './store/cartContext'
import { setUser } from './utils/auth'

import Header from './base/Header'
import Sold from './base/header/sold-header/Sold'
import Footer from './base/Footer'


import Register from './views/auth/Register'
import Login from './views/auth/Login'
import Logout from './views/auth/Logout'
import Profile from './views/auth/Profile'
import ForgotPassword from './views/auth/ForgotPassword'


import Menu from './views/shop/Menu'
import Products from './views/shop/Products'
import ProductDetail from './views/shop/ProductDetail'

import Cart from './views/order/Cart'
import Checkout from './views/order/Checkout'
import OrderComplete from './views/order/OrderComplete'
import ClientOrder from './views/order/ClientOrder'
import ClientOrderDetail from './views/order/ClientOrderDetail'


function App() {

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn());
  const userData = useAuthStore((state) => state.user());

  useEffect(() => {
    setUser()
  }, [isLoggedIn])

  return (
      <div data-plugin-page-transition style={{marginTop: "45.3125px", transition: "margin 300ms"}}>
      <BrowserRouter>

        <div className="body">
        <Sold />

        <Header />
        <MainWrapper>
          <Routes>
            {/* Auth */}
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/profile/:user/:pid' element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password/:otp' element={<PrivateRoute><Profile /></PrivateRoute>} />

            {/* Shop */}
            <Route path='/' element={<Menu />} />
            <Route path='/products' element={<Products />} />
            <Route path='/:tag/products' element={<Products />} />
            <Route path='/:category/products' element={<Products />} />
            <Route path='/:category/:subCategory/products' element={<Products />} />
            <Route path='/product-detail/:slug' element={<ProductDetail isLoggedIn={isLoggedIn} />} />

            {/* Order */}
            <Route path='/cart/:uid' element={<PrivateRoute><Cart /></PrivateRoute>} />
            <Route path='/checkout/:user_uid' element={<PrivateRoute><Checkout /></PrivateRoute>} />
            <Route path='/order-complete/:cid/:stripeSessionId' element={<PrivateRoute><OrderComplete /></PrivateRoute>} />
            <Route path='/client-order/:profile_pid' element={<PrivateRoute><ClientOrder /></PrivateRoute>} />
            <Route path='/client-order/:profile_pid/:checkout_coid' element={<PrivateRoute><ClientOrderDetail /></PrivateRoute>} />
          </Routes>
        </MainWrapper>
        <Footer />
        </div>
        </BrowserRouter>

      </div>
  )
}

export default App

