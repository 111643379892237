import React, { useEffect, useState } from 'react'
import TopOrderStep from './TopOrderStep'
import { fetchData, fetchDataForUser } from '../../utils/shop'
import { useParams } from 'react-router-dom'

function OrderComplete() {
	const params = useParams()

	const stripeSessionId = params['stripeSessionId']
	const [order, setOrder] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(true)
		fetchDataForUser(`/order/order-complete/${stripeSessionId}`, setOrder, setLoading)
	}, [])
	console.log(order[0])

  return (
    <div role="main" className="main shop pb-4">

				<div className="container">

					<TopOrderStep active="stepThree" />
					{order.map((o, index) => (
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-width-3 border-radius-0 border-color-success">
								<div className="card-body text-center">
									<p className="text-color-dark font-weight-bold text-4-5 mb-0"><i className="fas fa-check text-color-success me-1"></i> Merci d'avoir commandé chez nous !</p>

								</div>
							</div>
							<div className="d-flex flex-column flex-md-row justify-content-between py-3 px-4 my-4">
								<div className="text-center">
									<span>
										Order Number <br/>
										<strong className="text-color-dark">{o.coid}</strong>
									</span>
								</div>
								<div className="text-center mt-4 mt-md-0">
									<span>
										Date <br/>
										<strong className="text-color-dark">{o.date}</strong>
									</span>
								</div>
								<div className="text-center mt-4 mt-md-0">
									<span>
										Email <br/>
										<strong className="text-color-dark">abc@abc.com</strong>
									</span>
								</div>
								<div className="text-center mt-4 mt-md-0">
									<span>
										Payment Method <br/>
										<strong className="text-color-dark">Stripe</strong>
									</span>
								</div>
							</div>
							<div className="card border-width-3 border-radius-0 border-color-hover-dark mb-4">
								<div className="card-body">
									<h4 className="font-weight-bold text-uppercase text-4 mb-3">Votre Commande</h4>
									<table className="shop_table cart-totals mb-0">
										<tbody>
											<tr>
												<td colSpan="2" className="border-top-0">
													<strong className="text-color-dark">Produits achetés</strong>
												</td>
											</tr>
											<tr>
												{o?.cart.product?.map((p, indexC) => (
													<>
													
													<td>
													{console.log(p)}
														<strong className="d-block text-color-dark line-height-1 font-weight-semibold">{p.product.name} <span className="product-qty">x{p.quantity}</span></strong>
													</td>
													<td className="text-end align-top">
														<span className="amount font-weight-medium text-color-grey">{p.price}€</span>
													</td>
													</>
												))}
												
											</tr>

											<tr className="cart-subtotal">
												<td className="border-top-0">
													<strong className="text-color-dark">Sous-total</strong>
												</td>
												<td className="border-top-0 text-end">
													<strong><span className="amount font-weight-medium">{o?.cart.sub_total}€</span></strong>
												</td>
											</tr>
											<tr className="shipping">
												<td className="border-top-0">
													<strong className="text-color-dark">Livraison</strong>
												</td>
												<td className="border-top-0 text-end">
													<strong><span className="amount font-weight-medium">{o?.cart.shipping_fee == 0 ? "Gratuit" : o?.cart.shipping_fee}</span></strong>
												</td>
											</tr>
											<tr className="total">
												<td>
													<strong className="text-color-dark text-3-5">Total</strong>
												</td>
												<td className="text-end">
													<strong className="text-color-dark"><span className="amount text-color-dark text-5">{o?.cart.total}€</span></strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							{/* <div className="row pt-3">
								<div className="col-lg-6 mb-4 mb-lg-0">
									<h2 className="text-color-dark font-weight-bold text-5-5 mb-1">Billing Address</h2>
									<ul className="list list-unstyled text-2 mb-0">
										<li className="mb-0">John Doe Junior</li>
										<li className="mb-0">Street Name, City</li>
										<li className="mb-0">State AL 85001</li>
										<li className="mb-0">123 456 7890</li>
										<li className="mt-3 mb-0">abc@abc.com</li>
									</ul>
								</div>
								<div className="col-lg-6">
									<h2 className="text-color-dark font-weight-bold text-5-5 mb-1">Shipping Address</h2>
									<ul className="list list-unstyled text-2 mb-0">
										<li className="mb-0">John Doe Junior</li>
										<li className="mb-0">Street Name, City</li>
										<li className="mb-0">State AL 85001</li>
										<li className="mb-0">123 456 7890</li>
										<li className="mt-3 mb-0">abc@abc.com</li>
									</ul>
								</div>
							</div> */}
						</div>
					</div>
					))}
					

				</div>

			</div>
  )
}

export default OrderComplete
