import React from 'react';

function SearchBar() {
  return (
    <div className="header-nav-features header-nav-features-no-border col-lg-5 col-xl-6 px-0 ms-0">
      <div className="header-nav-feature ps-lg-5 pe-lg-4">
        <form role="search" action="page-search-results.html" method="get">
          <div className="search-with-select">
            <a href="#" className="mobile-search-toggle-btn me-2" data-porto-toggle-class="open">
              <i className="icons icon-magnifier text-color-dark text-color-hover-primary"></i>
            </a>
            <div className="search-form-wrapper input-group">
              <input
                className="form-control text-1"
                id="headerSearch"
                name="q"
                type="search"
                defaultValue=""
                placeholder="Search..."
              />
              <div className="search-form-select-wrapper">
                <div className="custom-select-1">
                  <select name="category" className="form-control form-select">
                    <option value="all">All Categories</option>
                    <option value="fashion">Fashion</option>
                    <option value="electronics">Electronics</option>
                    <option value="homegarden">Home & Garden</option>
                    <option value="motors">Motors</option>
                    <option value="features">Features</option>
                  </select>
                </div>
                <button className="btn" type="submit">
                  <i className="icons icon-magnifier header-nav-top-icon text-color-dark"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SearchBar;
