import React, { useContext } from 'react';

function SocialNetwork() {
  return (
    <ul className="header-social-icons social-icons social-icons-clean social-icons-icon-gray">
        <li className="social-icons-facebook">
            <a href="http://www.facebook.com/" target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a>
        </li>
        <li className="social-icons-twitter">
            <a href="http://www.twitter.com/" target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a>
        </li>
        <li className="social-icons-linkedin">
            <a href="http://www.linkedin.com/" target="_blank" title="Linkedin"><i className="fab fa-linkedin-in"></i></a>
        </li>
    </ul>
  );
}

export default SocialNetwork;
