import React from 'react'

function Footer() {
  return (
    <footer id="footer" className="footer-texts-more-lighten">
		<div className="container">
			<div className="row py-4 my-5">
				<div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
					<h5 className="text-4 text-color-light mb-3">CONTACT INFO</h5>
					<ul className="list list-unstyled">
						<li className="pb-1 mb-2">
							<span className="d-block font-weight-normal line-height-1 text-color-light">ADDRESS</span> 
							1234 Street Name, City, State, USA
						</li>
						<li className="pb-1 mb-2">
							<span className="d-block font-weight-normal line-height-1 text-color-light">PHONE</span>
							<a href="tel:+1234567890">Toll Free (123) 456-7890</a>
						</li>
						<li className="pb-1 mb-2">
							<span className="d-block font-weight-normal line-height-1 text-color-light">EMAIL</span>
							<a href="mailto:mail@example.com">mail@example.com</a>
						</li>
						<li className="pb-1 mb-2">
							<span className="d-block font-weight-normal line-height-1 text-color-light">WORKING DAYS/HOURS </span>
							Mon - Sun / 9:00AM - 8:00PM
						</li>
					</ul>
					<ul className="social-icons social-icons-clean-with-border social-icons-medium">
						<li className="social-icons-instagram">
							<a href="http://www.instagram.com/" className="no-footer-css" target="_blank" title="Instagram"><i className="fab fa-instagram"></i></a>
						</li>
						<li className="social-icons-twitter mx-2">
							<a href="http://www.twitter.com/" className="no-footer-css" target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a>
						</li>
						<li className="social-icons-facebook">
							<a href="http://www.facebook.com/" className="no-footer-css" target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a>
						</li>
					</ul>
				</div>
				<div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
					<h5 className="text-4 text-color-light mb-3">CUSTOMER SERVICE</h5>
					<ul className="list list-unstyled mb-0">
						<li className="mb-0"><a href="contact-us-1.html">Help & FAQs</a></li>
						<li className="mb-0"><a href="services.html">Order Tracking</a></li>
						<li className="mb-0"><a href="#">Shipping & Delivery</a></li>
						<li className="mb-0"><a href="#">Orders History</a></li>
						<li className="mb-0"><a href="#">Advanced Search</a></li>
						<li className="mb-0"><a href="#">Login</a></li>
						<li className="mb-0"><a href="about-us-1.html">Careers</a></li>
						<li className="mb-0"><a href="#">About Us</a></li>
						<li className="mb-0"><a href="#">Corporate Sales</a></li>
					</ul>
				</div>
				<div className="col-md-6 col-lg-3 mb-5 mb-md-0">
				</div>
				<div className="col-md-6 col-lg-3">
					<h5 className="text-4 text-color-light mb-3">SUBSCRIBE NEWSLETTER</h5>
					<p className="mb-2">Get all the latest information on events, sales and offers. Sign up for newsletter:</p>
					<div className="alert alert-success d-none" id="newsletterSuccess">
						<strong>Success!</strong> You've been added to our email list.
					</div>
					<div className="alert alert-danger d-none" id="newsletterError"></div>
					<form id="newsletterForm" className="form-style-5 opacity-10" action="php/newsletter-subscribe.php" method="POST">
						<div className="row">
							<div className="form-group col">
								<input className="form-control" placeholder="Email Address" name="newsletterEmail" id="newsletterEmail" type="text" />
							</div>
						</div>
						<div className="row">
							<div className="form-group col">
								<button className="btn btn-primary btn-rounded btn-px-4 btn-py-2 font-weight-bold" type="submit">SUBSCRIBE</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div className="container">
			<div className="footer-copyright footer-copyright-style-2 pt-4 pb-5">
				<div className="row align-items-center justify-content-md-between">
					<div className="col-12 col-md-auto text-center text-md-start mb-2 mb-md-0">
						<p className="mb-0">Porto Template © 2021. All Rights Reserved</p>
					</div>
					<div className="col-12 col-md-auto">
						<div className="payment-cc justify-content-center justify-content-md-end">
							<i className="fab fa-cc-visa"></i>
							<i className="fab fa-cc-paypal"></i>
							<i className="fab fa-cc-stripe"></i>
							<i className="fab fa-cc-mastercard"></i>
							<i className="fab fa-cc-apple-pay"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
  )
}

export default Footer