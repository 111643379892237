import React, { useContext } from 'react';
import {Link} from 'react-router-dom'


function Logo() {
  return (
    <div className="header-logo z-index-2 col-lg-2 px-0">
        <Link to="/">
            <img alt="ataporte" width="200" height="42" data-sticky-width="82" data-sticky-height="40" data-sticky-top="84" src={`/img/logo-ataporte.jpg`}/>
        </Link>
    </div>
  );
}

export default Logo;

