import { useState, useEffect } from "react"
import {Link} from 'react-router-dom'
import { useAuthStore } from '../../../store/authContext'
import { fetchData, fetchDataForUser } from "../../../utils/shop";
import { UserData } from "../../../utils/shop";


import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import useAxios from "../../../utils/useAxios";

function UserAction() {
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn());
    const userData = UserData()
    const userUid = userData.uid
    const userId = userData.id
    const request = useAxios()

    const [cartData, setCartData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchDataForUser(`order/cart/${userUid}`, setCartData, setLoading)
        setLoading(true)
    }, [userUid])

    const products = cartData[0]?.product
    
    const [showCart, setShowCart] = useState('')
    const handleShowCart = () => {
        setShowCart((prevState) => (prevState === '' ? 'show' : ''))
    }

    // Delete product from cart
	const handleDeleteProduct = async (cpid, cid) => {
		const payload = { cpid, cid };
		try {
			const response = await request.delete('/order/delete-cart', { data: payload })
			.then(response => {
			window.location.reload()
			// console.log('Product deleted successfully', response);
			})
			.catch(error => {
				// console.error('Error deleting product', error);
			});
		} catch (error) {
			console.error('Error sending the request', error);
		}
	};
    
  return (
    <div className="d-flex col-auto col-lg-2 pe-0 ps-0 ps-xl-3">
        <ul className="header-extra-info">
            <li className="ms-0 ms-xl-4">
                <div className="header-extra-info-icon">
                    <Link to={`/profile/${userData.id}/${userData.profile_pid}`} className="text-decoration-none text-color-dark text-color-hover-primary text-2">
                        <i className="icons icon-user"></i>
                    </Link>
                </div>
            </li>
            <li className="me-2 ms-3">
                <div className="header-extra-info-icon">
                    <a href="#" className="text-decoration-none text-color-dark text-color-hover-primary text-2">
                        <i className="icons icon-heart"></i>
                    </a>
                </div>
            </li>
        </ul>
        <div className="header-nav-features ps-0 ms-1">
            <div className="header-nav-feature header-nav-features-cart header-nav-features-cart-big d-inline-flex top-2 ms-2">
                <a href="#" className="header-nav-features-toggle">
                    <img onClick={handleShowCart} src="/img/icons/icon-cart-big.svg" height="30" alt="" className="header-nav-top-icon-img"/>
                    {products?.length !== undefined &&
                    <span className="cart-info">
                        <span className="cart-qty">{products?.length}</span>
                    </span>
                    }
                </a>
                <div className={`header-nav-features-dropdown ${showCart}`} id="headerTopCartDropdown">
                    <ol className="mini-products-list">
                        {products?.map((p, index) => (
                        <li className="item" key={index}>
                            <a href={`${p.product.slug}`} title={`${p.product.images[0].alt}`} className="product-image"><img src={`${p.product.images[0].image}`} alt={`${p.product.images[0].alt}`}/></a>
                            <div className="product-details">
                                <p className="product-name">
                                    <a href={`${p.product.slug}`}>{p.product.name}</a>
                                </p>
                                <p className="qty-price">
                                        {p.quantity} X <span className="price">{p.price}€</span>{p.quantity > 1 ? ` = ${(p.quantity * p.price).toFixed(2)}€`: ''}
                                </p>
                                <a onClick={() => handleDeleteProduct(p.cpid, p.cart)}  style={{cursor: "pointer"}} title="Enlever le produit du panier" className="btn-remove"><i className="fas fa-times"></i></a>
                            </div>
                        </li>
                        ))}
                        
                    </ol>
                    <div className="totals">
                        <span className="label">Total:</span>
                        <span className="price-total"><span className="price">{cartData[0]?.total}€</span></span>
                    </div>
                    <div className="actions" onClick={handleShowCart}>
                        {isLoggedIn &&
                        <div>
                            <Link className="btn btn-dark" to={`/cart/${userUid}`}>Panier</Link>
                            {/* ${uid}/${cid} */}
                            <Link className="btn btn-primary" to={`/checkout/${userUid}`}>Commander</Link>
                        </div>
                        }
                        {!isLoggedIn &&
                        <div>
                            <Link  className="btn btn-dark" to={`/login`}>Connexion</Link>
                            <Link  className="btn btn-primary" to={`/register`}>Inscription</Link>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default UserAction;

