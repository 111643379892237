import { create } from 'zustand';
import Cookies from 'js-cookie';

// Création du store Zustand pour le panier
const useCartStore = create((set, get) => ({
    cartItems: Cookies.get('cartItems') || [],  // Charger depuis les cookies au départ
    totalItems: Cookies.get('totalItems') ? parseInt(Cookies.get('totalItems')) : 0,

    // Ajouter un produit au panier
    addProduct: (id, sku, quantity, weight) => {
        const cartItems = get().cartItems;
        const product = {id, sku, quantity, weight}
        const updatedCart = [...cartItems, product];

        set({ cartItems: updatedCart, totalItems: updatedCart.length });

        // Mettre à jour les cookies
        Cookies.set('cartItems', JSON.stringify(updatedCart));
        Cookies.set('totalItems', updatedCart.length.toString());
    },

    // Enlever un produit du panier
    removeProduct: (productId) => {
        const cartItems = get().cartItems;
        const updatedCart = cartItems.filter(item => item.id !== productId);

        set({ cartItems: updatedCart, totalItems: updatedCart.length });

        // Mettre à jour les cookies
        Cookies.set('cartItems', JSON.stringify(updatedCart));
        Cookies.set('totalItems', updatedCart.length.toString());
    },

    // Réinitialiser le panier
    clearCart: () => {
        set({ cartItems: [], totalItems: 0 });

        // Supprimer les cookies
        Cookies.remove('cartItems');
        Cookies.remove('totalItems');
    },
}));

export {useCartStore}